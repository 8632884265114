import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { get } from "../apiService";
import HomeProduct from "./HomeProduct";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import CustomerReviewCard from "./CustomerReviewCard";
import reviews from "../Assert/reviews";
import NewHomeCat from "./NewHomeCat";
import HomeWedding from "./HomeWedding";
import "../Css/NewHomeCat.css";
import RajputanaSherwani from "../Assert/new home about/Rajputana Sherwani.jpg";
import Sherwani from "../Assert/new home about/Sherwani.jpg";
import IconSec from "./IconSec";
import HomeBanner from "./HomeBanner";
import videoFile01 from "../Assert/kataria-reel1.mp4";
import videoFile02 from "../Assert/kataria-reel2.mp4";
import { Button, Modal } from 'antd';
import offer from "../Assert/Offer.jpeg";
import { MetaTags } from 'react-meta-tags';

const Home = () => {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchCategory();
    const timer = setTimeout(() => {
      setIsModalOpen(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  const fetchCategory = async () => {
    try {
      const page = 1;
      const limit = 20;
      const response = await get(
        `/category.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&action=fetch&page=${page}&limit=${limit}`
      );
      if (response) {
        localStorage.setItem("category", JSON.stringify(response.list));
        setCategory(response.list);
      }
    } catch (error) {
      console.log("error fetch product category");
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
       {/* <MetaTags>
        <title>Men's Suiting | Kataria</title>
        <meta title="Men's Suiting | Kataria" />
        <meta name="description" content="Discover premium Men's Suiting at Kataria, offering sophisticated designs, perfect fits, and unmatched quality for every occasion. Dress to impress." />
        <meta name="keywords" content="Men's Suiting,Shirting ,Readymades Kurta,Best Suitings,Best Kurtas,Jodhpuri Kurtas ,Mens Embroidered Kurta,Kurta Pajama For Men,Maroon Colour Kurta With Dupatta Daman Embroidery Work,Red Colour Kurta With Embroidery ,Mint Pink Colour Open Jacket Kurta Set,Open Jacket Kurta Pajama Set,Best Open Jacket Kurta Pajama Set,Cream Colour Multi Threadwork Jacket Indowestern,Digital Print Sky Blue Colour Embroidered Rayon Kurta,Red Colour Panel Embroidery Kurta In Georgette Fabric,Digital Print Multi-Colour Rayon Kurta,White & Blue Multi-Colour Shaded Kurta With Mirror Work,Light Onion Colour Kurta With Mirror Work,Lavender Colour Kurta With Work,Peach Colour Kurta With Work,Cream Colour Kurta With Embroidery,Multi-Colour Position Print Kurta With Mirror Work,Sea Green Colour Kurta With Mirror Work,Black Colour Jacket Jodhpuri With Work ,Zig-Zig Embroidery Work Jacket Jodhpuri,Dark Pink Colour Embroidery Kurta,Onion Pink Colour Georgette Kurta With Panel Embroidery Work,Black Colour Jacket Indowestern Set,Maroon Colour Kurta With Long Jacket Set,Teal Blue Colour Velvet Kurta With Long Jacket Set,Wine Colour Velvet Kurta With Long Jacket Set,Yellow And White Multi-Colour Shaded Kurta With Work" />
        <link rel="canonical" href="https://katariashubli.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Men's Suiting | Kataria" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://katariashubli.com/" />
        <meta property="og:description" content="Discover premium Men's Suiting at Kataria, offering sophisticated designs, perfect fits, and unmatched quality for every occasion. Dress to impress." />
        <meta property="og:image" content="https://katariashubli.com/static/media/kartaria%20logo.61ab17e04111050f3cc9.png" />
      </MetaTags> */}
      {/* <MainBanner /> */}
      {/* <ReadyToShips /> */}
      {/* <ShopCollection /> */}
      {/* <ExploreMore /> */}
      <HomeBanner />
      {/* <CircleComp category={category} /> */}
      <NewHomeCat />
      {/* <HomeWedding/> */}
      <HomeProduct />
      <IconSec />
      <section className="home-section-new-home-category">
        <div className="container">
          <div className="home-section-new-home-details-con-main">
            <div className="home-section-head-main-con">
              {/* <h2>FESTIVE hues</h2> */}
              <h3>FESTIVE HUES</h3>
            </div>
            <div className="home-section-second-main-new-category-con">
              <div className="row">
                <div className="col-6">
                  <div className="home-section-section-main-con-category-video-main-con">
                    <div className="home-section-section-main-con-category-video-con">
                      <video width="100%" height="50vh" muted loop controls>
                        <source src={videoFile01} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="home-section-section-main-con-category-video-main-con">
                    <div className="home-section-section-main-con-category-video-con">
                      <video width="100%" height="50vh" muted loop controls>
                        <source src={videoFile02} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Home-Section-7">
        <div className="container">
          <div className="Home-Section-7-testimonial">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {reviews.map((review, index) => (
                <SwiperSlide key={index}>
                  <CustomerReviewCard
                    description={review.reviews}
                    rate={review.stars}
                    name={review.user_name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      <Footer />

      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}  className="custom-modal" width="max-content">
            <img src={offer} alt="offer" className="popup-offer"/>
      </Modal>
    </>
  );
};

export default Home;
